/**
Login Form
*/
input.login-container__username::placeholder,
input.login-container__password::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; /* Firefox */
}
.login-container {
  margin: 0 auto;
  background-color: $theme-color-1;
  display: table;
  width: 100%;
  height: 100vh;

  .login-container__language {
    float: right;
  }

  .login-container__form {
    position: absolute;
    width: 350px;
    height: 380px;
    background-color: rgba(248, 247, 249, 0.2);
    top: 50%;
    left: 50%;
    // margin-top: -250px;
    // margin-left: -150px;
    padding: 10px 40px;
    border-radius: 30px;
    transform: translate(-50%, -50%);

    .side-drawer-logo-container {
      text-align: center;
      margin: 30px;
      margin-top: 70px;
      color: #fff;

      .side-drawer-account-container {
        min-height: 100px;
        text-align: center;
        position: relative;

        .side-drawer-accounts__avatar {
          position: absolute;
          left: 0;
          top: 0;

          img {
            width: 72px !important;
            height: 72px !important;
            border-radius: 50%;
          }
        }
        .side-drawer-accounts__name {
          color: #fff;
          position: absolute;
          right: 0px !important;
        }
      }
    }

    .side-drawer-logo {
      width: 110px;
      height: 100px;
      background: url($logoPath) no-repeat;
      background-position: center center;
      display: inline-block;
      margin: 0 auto;
    }

    .login-container__input {
      font-size: 16px;
      color: #f9f9f9;

      .login-container__username {
        font-size: 18px;
        padding: 20px;
        margin-bottom: 15px;
        background-color: rgba(248, 247, 249, 0.3);
        border: 1px solid rgba(248, 247, 249, 0.2);
        border-radius: 13px;
        background: url("./assets/img/login-username.svg") top left no-repeat;
        background-size: 23px;
        background-position: 18px 8px;
        padding-left: 60px;
        color: white;
        outline: unset;
        height: calc(1.5em + 0.75rem + 2px);
      }
      .login-container__password {
        margin-top: 15px;
        font-size: 18px;
        padding: 20px;
        background-color: rgba(248, 247, 249, 0.3);
        border: 1px solid rgba(248, 247, 249, 0.2);
        border-radius: 13px;
        background: url("./assets/img/login-password.svg") top left no-repeat;
        background-size: 21px;
        background-position: 18px 8px;
        padding-left: 60px;
        color: white;
        outline: unset;
        height: calc(1.5em + 0.75rem + 2px);
      }

      .login-container__checkbox-wraper {
        padding: 5px 0px 20px 0px;

        .login-container__checkbox {
          font-size: 15px;
          user-select: none;
          margin-left: -5px;

          label:before {
            background-color: rgba(78, 143, 255, 0.8);
          }
        }
      }
    }

    .login-container__btn-wraper {
      text-align: center;
      .login-container__btn {
        margin: 0 auto;
        background-color: rgba(78, 143, 255, 0.8);
        width: 100%;
        border: 1px solid rgba(78, 143, 255, 1);
        border-radius: 20px;
        font-size: 20px;
        color: #fff;

        &:hover {
          background-color: rgba(96, 154, 255, 0.8);
          color: #f9f9f9;
        }
      }
    }
  }
}
/** End Login **/

.h-100vh {
  min-height: calc(100vh - 110px) !important;
}

.container__title,
.container__title-outside {
  text-transform: uppercase;
  font-size: 26px;
  color: #19274f;
  // margin: 20px 0px 5px 0px;

  h1 {
    font-weight: 900;
    font-size: 1.25rem;
    // padding-bottom: 10px;
    display: inline-block;
  }
}