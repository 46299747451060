$bgPath: "assets/img/checkin/bg.png";

.getaway-contaier {
    margin: 0 auto;
    display: table;
    width: 100%;
    height: 100vh;
    position: relative; /* Container should have relative position */

    background: url($bgPath) no-repeat;
    background-position: top left;
    background-size: cover;
    background-position: top left;
}

.isshow {
    opacity: 1 !important;
}

.getaway-info {
    opacity: 0;
    padding: 20px;
    top: 38vh;
    height: 250px;
    width: 400px;
    transition: opacity 0.5s linear;

    h6 {
        padding: 20px 0px;
    }
   .input-group {
        width: 350px;
        margin-bottom: 10px;

        .left {
            width: 80px;
            text-align: start;
        }
        input {
            color: #000;
            font-weight: 600;
        }
   }
}

.getaway-qrcode {

    &__form {
        position: absolute; /* Child should have absolute position */
        bottom: 10px;
        left: 0;
        height: 30px;
    }
}

@media (max-width: 480px) {
    .getaway-info {
        width: 340px;
    }
}
