$bgPath: "assets/img/checkin/bg.png";
$bgMobilePath: "assets/img/checkin/bg-mobile.png";

.checkin-container-analytics {
    margin: 3px 0px 20px 0px;

    &--wrapper {
        max-width: 1200px;
        margin: 0 auto;
    }
}

.checkin__container {
    margin: 0 auto;
    display: table;
    width: 100%;
    height: 100vh;

    background: url($bgPath) no-repeat;
    background-position: top left;
    background-size: cover;
    background-position: top left;

    &__form {
        h6 {
            text-align: center;
            padding: 10px 0px;
        }

        position: absolute;
        width: 350px;
        height: 500px;
        background-color: rgba(255, 255, 255, 0.7);
        top: 50%;
        left: 30%;
        padding: 20px 40px;
        border-radius: 30px;
        transform: translate(-50%, -50%);
    }

    &__scroll {
        height: 325px;
        padding: 20px 40px;
    }
    &__footer {
        padding: 5px 40px;
    }
}

@media (max-width: 768px) {
    .checkin__container {
        &__form {
            left: 40%;
        }
    }
}

@media (max-width: 480px) {
    .checkin__container {
        background: url($bgMobilePath) no-repeat;
        background-size: contain;
        background-position: center;
        background-color: #022b37;

        .rs-form-group {
            margin-bottom: 8px !important;
        }

        &__form {
            position: absolute;
            width: 280px;
            height: 380px;
            background-color: rgba(255, 255, 255, 0.5);
            top: 35%;
            left: 50%;
            padding: 0px 20px;
            border-radius: 30px;
            transform: translate(-50%, -50%);
        }

        .divider {
            margin: 8px 0px;
        }
    }
}
