.toolbar {
    position: fixed;
    // position: absolute;
    top: 0;
    /* left: 0; */
    /* width: 100%; */
    right: 0;
    width: 100%;
    // background: #fff;
    // height: $toolbar-height;
    z-index: 999;
    // box-shadow: 0px 0px 3px #ccc;
  
    &__navigation {
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0 1rem;
      flex-direction: row-reverse;
  
      .toolbar__search {
        // margin-left: 1rem;
        margin-top: 8px;
  
        span {
          margin-right: 8px;
          font-size: 15px;
        }
  
        div {
          position: relative;
          margin-top: 15px;
  
          span::after {
            content: "";
            position: absolute;
            right: 15px;
            top: 10px;
            bottom: 0;
            width: 20px;
            background: url("./assets/img/search.svg") top left no-repeat;
            cursor: pointer;
          }
        }
  
        input {
          border-radius: 20px;
          font-size: 17px;
        }
      }
  
      .toolbar__qr {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 0px 15px;
  
        .toolbar__qr__icon {
          background: url("./assets/img/qrcode.svg") top left no-repeat;
          width: 40px;
          height: 40px;
          position: absolute;
        }
      }
    }
  
    &_navigation-items {
      cursor: pointer;
  
      .toolbar_navigation-items__setting {
        background: url("./assets/img/setting-icon.svg") top left no-repeat;
        width: 35px;
        height: 35px;
        padding: 15px;
        padding-top: 5px;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
      }
      li {
        padding: 0 0.5rem;
      }
      a {
        color: #070707;
        text-decoration: none;
      }
      a:hover,
      a:active {
        color: #fa923f;
      }
    }
  }